import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import Layout from './layout';

import Metronome from '../metronome/slider';
import PianoPlayback from '../piano/playback';
import PianoRecord from '../piano/record';

import Editor from '../sequence/component/editor';

import SearchSequence from '../search';

import UserAdminList from '../user/admin';
import UserProfile from '../user/profile/index';
import UserCreate from '../user/form/create';
import UserUpdate from '../user/form/update';
import UserLogin from '../user/form/login';
import Auth from '../config/auth';

const Routes = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route path="/user/login" exact component={UserLogin}/>
                    <Route path="/user/logout" logout exact component={UserLogin}/>
                    <Route path="/user/create" component={UserCreate}/>

                    <Auth path="/" exact component={PianoPlayback}/>
                    <Auth exact path="/metronome" component={Metronome}/>
                    <Auth exact path="/piano/record" component={PianoRecord}/>
                    <Auth exact path="/piano/playback" component={PianoPlayback}/>
                    <Auth exact path="/sequence/search" component={SearchSequence}/>

                    <Auth exact path="/editor" component={Editor}/>

                    <Auth exact path="/user/admin" component={UserAdminList}/>
                    <Auth exact path="/user/profile" component={UserProfile}/>
                    <Auth path="/user/update/:id" component={UserUpdate}/>

                    <Route component={UserLogin}/>

                </Switch>
            </Layout>
        </BrowserRouter>
    );
};

export default Routes;
