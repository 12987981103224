import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import 'moment-timezone';
import Moment from 'react-moment';

import EditIcon from '@material-ui/icons/Edit';
import {withStyles} from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {styles} from './styles';

import {Query} from 'react-apollo';
import {USERS_GQL} from '../gql/query';

const DATETIME_USA = "MM/DD/YY hh:mm A";

class UserAdminIndex extends Component {
    rows = users => {
        const {classes} = this.props;

        return (
            <Fragment>
                <h2>Users</h2>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Instrument</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {users.map((user) => {
                            let createdAt = '';
                            if (user.createdAt) {
                                createdAt = <Moment format={DATETIME_USA} unix>{user.createdAt * .001}</Moment>
                            }

                            return (
                                <TableRow key={user.id}>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.instrument}</TableCell>
                                    <TableCell>{createdAt}</TableCell>
                                    <TableCell><Link to={`user/update/${user.id}`}><EditIcon/></Link></TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Fragment>
        );
    };

    render() {
        return (
            <Query query={USERS_GQL}>
                {({loading, data}) => {
                    if (loading) return 'Loading ...';

                    return this.rows(data.users);
                }}
            </Query>
        );
    }
}

UserAdminIndex.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserAdminIndex);
