import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Paper} from '@material-ui/core';
import {styles} from '../styles';

export class StepFiveSuccess extends Component {
    render() {
        const {classes, values} = this.props;

        return (
            <Grid justify="center" container direction="column" alignItems="center">
                <Grid item xs={6}>
                    <Paper className={classes.thankYou} elevation={1}>
                        <Typography>
                            Thank You For Your
                            Submission <strong>{values.firstName}</strong>
                        </Typography>

                        <Typography>
                            You will get an email with further instructions
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

StepFiveSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepFiveSuccess);
