import React, {Component, Fragment} from 'react';

import SearchBar from './component/bar';
import SearchResult from './component/result';

class Search extends Component {
    render() {
        return (
            <Fragment>
                <SearchBar/>
                <SearchResult/>
            </Fragment>
        );
    }
}

export default Search;
