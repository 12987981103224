import React, {Fragment} from 'react';
import Tone from './tone';
import {RecordedTone} from "../../style/recorded-tone";
import {BtnClear} from "../ui/button";

function renderTones(tones) {
    return tones.map((event, index) => {
        return (
            <Tone key={index} ticks={event.ticks} midiNumber={event.midiNumber}/>
        );
    });
}

function renderInstructions() {
    return (
        <div className="instructions">
            <strong>Instructions</strong>
            <ul>
                <li>Click the record button to arm the recorder, the metronome will begin ticking.</li>
                <li>Adjust the desired tempo using the tempo slider.</li>
                <li>Play any tone to begin recording.</li>
                <li>Click the record button once again to stop the recording.</li>
            </ul>
        </div>
    );
}

export default ({tones, onClickClear, children}) =>
    <Fragment>
        {children}

        <RecordedTone>
            {tones.length ? renderTones(tones) : renderInstructions()}
            {onClickClear &&
            <BtnClear onClick={onClickClear}
                      className="btn-clear"/>
            }
        </RecordedTone>
    </Fragment>

