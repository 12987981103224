import {Iodine} from '@kingshott/iodine';

const val = new Iodine();
val.is
/**
 * The username must be a valid email address
 *
 * @param value
 * @returns {string}
 */
const username = value => val.isEmail(value) ? '' : val.getErrorMessage('email');

/**
 * The only requirement for the password is the length
 *
 * @param value
 * @returns {string}
 */
const password = (value) => val.isMinimum(value, 8) ? '' : val.getErrorMessage('minimum:8');

/**
 * To check that the
 * @param value
 * @param password
 * @returns {string}s
 */
const passwordConfirm = (value, password) => value === password ? '' : 'Passwords do not match';

const firstName = value => val.isMinimum(value, 3) ? '' : val.getErrorMessage('minimum:3')

const lastName = value => val.isMinimum(value, 3) ? '' : val.getErrorMessage('minimum:3')

const city = value => val.isMinimum(value, 3) ? '' : val.getErrorMessage('minimum:3')

const state = value => val.isMinimum(value, 2) ? '' : val.getErrorMessage('minimum:2')

const country = value => val.isMinimum(value, 3) ? '' : val.getErrorMessage('minimum:3')

const instrument = value => val.isMinimum(value, 3) ? '' : val.getErrorMessage('minimum:3')

const bio = value => val.isMinimum(value, 32) ? '' : val.getErrorMessage('minimum:32')

export const validate = (field, value, extra) => {
    let error = '';

    switch (field) {
        case 'username':
            error = username(value);
            break;
        case 'password':
            error = password(value);
            break;
        case 'passwordConfirm':
            error = passwordConfirm(value, extra);
            break;
        case 'firstName':
            error = firstName(value);
            break;
        case 'lastName':
            error = lastName(value);
            break;
        case 'city':
            error = city(value);
            break;
        case 'state':
            error = state(value);
            break;
        case 'country':
            error = country(value);
            break;
        case 'instrument':
            error = instrument(value);
            break;
        case 'bio':
            error = bio(value);
            break;
    }

    return error;
}