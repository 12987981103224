import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import styles, {GRID_SPACING} from '../../style/grid';
import Paper from '@material-ui/core/Paper';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import {
    KEY_SHIFT,
    KEY_CONTROL,
    KEY_OPTION,
    KEY_COMMAND,
    KEY_ALT,
} from '../../app/const/keyboard';
import {FormControlGroup} from '../../style/form-control-group';

class Keyboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            volume: null,
            frequency: null,
            metronome: null,
            octave: null,
        }
    }

    radio = (value, label) => (
        <FormControlLabel
            value={value}
            control={<Radio color="primary"/>}
            label={label}
            labelPlacement="end"/>

    );

    setting = (name, label, value) => {
        return (
            <FormControlGroup>
                <label>{label}</label>

                <Paper className={this.props.classes.paper}>
                    <FormControl>
                        <RadioGroup aria-label="position" name={name} value={value}
                                    onChange={e => this.setState({[name]: e.target.value})} row>
                            {this.radio(KEY_SHIFT, 'Shift')}
                            {this.radio(KEY_OPTION, 'Option')}
                            {this.radio(KEY_CONTROL, 'Control')}
                            {this.radio(KEY_COMMAND, 'Command')}
                            {this.radio(KEY_ALT, 'Alt')}
                        </RadioGroup>
                    </FormControl>
                </Paper>
            </FormControlGroup>
        );
    };

    render() {
        return (
            <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12}>
                    {this.setting('volume', 'Volume', this.state.volume)}
                    {this.setting('octave', 'Octave Offset', this.state.octave)}
                    {this.setting('metronome', 'Metronome Tempo', this.state.metronome)}
                    {this.setting('frequency', 'Tone', this.state.frequency)}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Keyboard);