let PROTOCOL = 'http';
let HOSTNAME = 'localhost:4070';
let HOSTNAME_API = 'localhost:4070';

if (process.env.NODE_ENV === 'production') {
    PROTOCOL += 's';
    HOSTNAME = 'metrotone.chelista.com';
    HOSTNAME_API = 'metrotone.api.chelista.com'
}

export const GRAPHQL_URL = `${PROTOCOL}://${HOSTNAME_API}/graphql`;
export const AUTH_URL = `${PROTOCOL}://${HOSTNAME_API}/auth/login`;

export const USA_DATE = 'MM/DD/YYYY';