import React, {Component, Fragment} from "react";
import SequenceList from "../container/list";
import SequenceActive from "../container/active";

class SequenceEditor extends Component {
    render() {
        return (
            <Fragment>
                <SequenceList/>
                <SequenceActive/>
            </Fragment>
        );
    }
}

export default SequenceEditor;
