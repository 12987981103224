import React, {Component} from 'react';

import {AutoSizer} from 'react-virtualized';
import {Piano} from 'react-piano';
import Synth from './synth';

class PianoWithRecording extends Component {
    constructor(props) {
        super(props);

        this.armed = false;

        this.state = {
            keysDown: {},
        };
    }

    onPlayNoteInput = midiNumber => {
        this.setState({
            notesRecorded: false,
        });
    };

    /**
     * To record the tones right before the next one begins
     *
     * @param midiNumber
     * @param prevActiveNotes
     */
    onStopNoteInput = (midiNumber, {prevActiveNotes}) => {
        if (this.armed) {
            if (this.state.notesRecorded === false) {
                this.recordNotes(prevActiveNotes, this.props.noteDuration);

                this.setState({
                    notesRecorded: true,
                });
            }
        }
    };

    /**
     * To record the note structure
     *
     * @param midiNumbers
     * @param duration
     */
    recordNotes = (midiNumbers, duration) => {
        if (this.props.recording.mode !== 'RECORDING') {
            return;
        }

        const newEvents = midiNumbers.map(midiNumber => {
            return {
                midiNumber,
                ticks: 0,
                duration,
            };
        });

        this.props.setRecording({
            tones: this.props.recording.tones.concat(newEvents),
        });
    };

    render() {
        this.armed = this.props.armed;

        const {mode, currentEvents} = this.props.recording;

        const activeNotes = mode === 'PLAYING'
            ? currentEvents.map(event => event.midiNumber)
            : null;

        return (
            <Synth
                stop={!this.armed}
                gain={this.props.gain}
                frequencyOffset={this.props.frequencyOffset}
                octaveOffset={this.props.octaveOffset}
                octaveOffsetDown={this.props.octaveOffsetDown}
                octaveOffsetUp={this.props.octaveOffsetUp}
                syncTicking={this.props.syncTicking}
                setTicking={this.props.setTicking}
                isTicking={this.props.isTicking}
                onLoad={({stopAllNotes}) => this.setState({stopAllNotes})}
                render={({isLoading, playNote, stopNote, stopAllNotes}) => (
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <Piano
                                recording={this.props.recording}
                                setRecording={this.props.setRecording}
                                noteRange={this.props.noteRange}
                                keyboardShortcuts={this.props.keyboardShortcuts}
                                activeNotes={activeNotes}
                                width={width}
                                playNote={playNote}
                                stopNote={stopNote}
                                disabled={isLoading}
                                onPlayNoteInput={this.onPlayNoteInput}
                                onStopNoteInput={this.onStopNoteInput}
                            />
                        )}
                    </AutoSizer>
                )}
            />
        );
    }
}

PianoWithRecording.defaultProps = {
    notesRecorded: false,
};

export default PianoWithRecording;
