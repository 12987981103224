import gql from 'graphql-tag';

export const GET_SEQUENCES = gql`
    query sequences {
        sequences {
            id
            name
            tones
            createdAt
            updatedAt
        }
    }
`;

export const GET_SEQUENCE = gql`
    query sequence($id: ID!) {
        sequence(id: $id) {
            id
            name
            tones
            createdAt
            updatedAt
        }
    }
`;

