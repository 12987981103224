export const GRID_SPACING = 1;

const styles = theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(2),
    },

    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },

    btnRecord: {
        marginTop: theme.spacing(1) - 2,
        marginRight: theme.spacing(2)
    },
});

export default styles;