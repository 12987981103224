import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {Query} from 'react-apollo';
import {GET_SEQUENCES} from '../../sequence/gql/query';
import SequenceList from './list';

class SearchResult extends Component {

    _sequences = sequences => (
        <dl>
            {_.map(sequences, sequence => {
                return (
                    <Fragment key={sequence.id}>
                        <dt>
                            {sequence.name} ({sequence.id})
                        </dt>
                        <dd>{sequence.description}</dd>
                    </Fragment>
                );
            })}
        </dl>
    );

    render() {
        return (
            <Query query={GET_SEQUENCES}>
                {({loading, error, data}) => {
                    if (loading) return 'Loading...';
                    if (error) return `Error! ${error.message}`;

                    return <SequenceList sequences={data.sequences}/>
                }}
            </Query>
        );
    }
}

export default SearchResult;
