import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Play from '@material-ui/icons/PlayArrow';
import Stop from '@material-ui/icons/StopRounded';
import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeOn from '@material-ui/icons/VolumeUp';
import Save from '@material-ui/icons/Save';
import Record from '@material-ui/icons/FiberManualRecord';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove'

const Icon = ({name, fontSize}) => {
    switch (name) {
        case 'delete':
            return <Delete fontSize={fontSize}/>;
        case 'play':
            return <Play fontSize={fontSize}/>;
        case 'stop':
            return <Stop fontSize={fontSize}/>;
        case 'volume-on':
            return <VolumeOn fontSize={fontSize}/>;
        case 'volume-off':
            return <VolumeOff fontSize={fontSize}/>;
        case 'increase':
            return <Add fontSize={fontSize}/>;
        case 'decrease':
            return <Remove fontSize={fontSize}/>;
        case 'save':
            return <Save fontSize={fontSize}/>;
        case 'record':
            return <Record fontSize={fontSize}/>;
        default:
            return false;
    }
};

export default Icon;
