import {Component} from 'react';
import PropTypes from 'prop-types';

import Dilla from 'dilla';
import Tick from './tick.wav';

const DEFAULT_TEMPO = 60;
const MAX_TEMPO = 220;
const MIN_TEMPO = 44;

class Metronome extends Component {
    constructor(props) {
        super(props);

        this.active = false;

        this.tempo = props.tempo;

        this.silent = props.silent;

        // To prevent restarts to close to each other
        this.restartTimer = null;

        this.tick = new Audio(Tick);

        const AudioContext = window.AudioContext || window.webkitAudioContext;

        this.dilla = new Dilla(new AudioContext(), {
            tempo: this.tempo,
            beatsPerBar: 3,
            loopLength: 2 // number of bars with default of 8
        });

        this.dilla.on('step', step => {
            if (!this.silent) {
                this.tick.play();
            }

            this.props.onTick();
        });
    }

    /**
     * To start the metronome if not already started
     */
    start = () => {
        if (!this.active) {
            this.active = true;

            this.startDilla();
        }
    };

    /**
     * To start the metronome and update the tempo if necessary
     *
     * The configuration of dilla is BAR.BEAT.TICK. The initializer
     * contains options where to stress the beats as well.
     *
     * https://www.npmjs.com/package/dilla
     */
    startDilla = () => {
        this.dilla.setTempo(this.tempo);

        this.dilla.set('metronome', [['*.*.1']]);

        this.dilla.start();
    };

    /**
     * To stop the ticks
     */
    stop = () => {
        this.active = false;

        this.dilla.stop();
    };

    /**
     * To restart the metronome with throttled tempo changes
     *
     * Some of the widgets may send tempo changes to fast.
     */
    restart = () => {
        if (!this.restartTimer) {
            const interval = 500;

            this.restartTimer = setInterval(() => {
                this.restartThrottled();
            }, interval);
        }
    };

    /**
     * To restart the metronome
     */
    restartThrottled = () => {
        clearInterval(this.restartTimer);

        this.restartTimer = null;

        this.startDilla();
    };

    /**
     * To set the tempo from the calling function
     *
     * @param tempo
     */
    setTempo = tempo => {
        if (this.tempo !== tempo) {
            this.tempo = tempo;

            if (this.active) {
                this.restart();
            }
        }
    };

    /**
     * To explicitly start or stop the metronome
     *
     * @param active
     */
    setActive = active => {
        if (active) {
            this.start();
        } else {
            this.stop();
        }
    };

    /**
     * To mute the tick without stopping the timer
     *
     * @returns {*}
     */
    setSilent = silent => {
        if (this.silent !== silent) {
            this.silent = silent;

            if (this.active) {
                this.restart();
            }
        }
    };

    render() {
        this.setTempo(this.props.tempo);
        this.setActive(this.props.active);
        this.setSilent(this.props.silent);

        return this.props.children;
    }
}

Metronome.propTypes = {
    active: PropTypes.bool,
    tempo: PropTypes.number,
    silent: PropTypes.bool,
    onTick: PropTypes.func
};

Metronome.defaultProps = {
    active: false,
    tempo: DEFAULT_TEMPO,
    silent: false,
    onTick: () => false
};

export {DEFAULT_TEMPO, MAX_TEMPO, MIN_TEMPO};

export default Metronome;
