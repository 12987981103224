import gql from 'graphql-tag';

export const USERS_GQL = gql`
    query users {
        users {
            id
            username
            firstName
            lastName
            city
            state
            country
            instrument
            bio
            createdAt
            updatedAt
        }
    }
`;

export const USER_GQL = gql`
    query user($id: ID!) {
        user (id: $id) {
            id
            username
            firstName
            lastName
            city
            state
            country
            instrument
            bio
            createdAt
            updatedAt
        }
    }
`;