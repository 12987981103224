export const MIN_MIDI_NUMBER = 49;
export const MAX_MIDI_NUMBER = 94;
export const MIN_OCTAVE_OFFSET = -1;
export const MAX_OCTAVE_OFFSET = 2;

export const tones = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];

export default class {
    constructor(note) {
        this.note = note.toUpperCase();
        const regex = /^([A-G]b?)(\d)$/;

        const [, tone, octave] = this.note.match(regex);
        this.tone = tone;
        this.octave = octave;
    }

    /**
     * To convert the octave digit to midi index
     *
     * 1. Extract the note and octave from the key
     *    C4, Bb3, F1, etc
     *
     * 2. Subtract one from the octave to account for zero index
     *
     * 3. Multiply by 12 to get the value to the correct octave
     *
     * 4. Add the zero index value of the note for the correct pitch
     *
     * @returns {number}
     */
    index = () => {
        let index = tones.indexOf(this.tone);

        return index + (this.octave - 1) * 12;
    };

    /**
     * To retrieve the frequency of the tone
     *
     * @returns {number|*}
     */
    frequency = () => frequencies[this.index()];

    /**
     * To return the next higher tone
     *
     * @returns {string | *}
     */
    up = () => {
        let next = this.tone;

        if (this.index() < MAX_MIDI_NUMBER) {
            if (this.tone === 'B') {
                next = 'C' + (parseInt(this.octave) + 1);
            } else {
                next = tones[tones.indexOf(this.tone) + 1] + this.octave;
            }
        }

        return next;
    };

    /**
     * To get the next lower tone
     *
     * @returns {string | * | number}
     */
    down = () => {
        let next = this.tone;

        if (this.index() > MIN_MIDI_NUMBER) {
            if (this.tone === 'C') {
                next = 'B' + (parseInt(this.octave) - 1);
            } else {
                next = tones[tones.indexOf(this.tone) - 1] + this.octave;
            }
        }

        return next;
    }
}

/**
 * To initialized all possible tones and their tempered tuning
 *
 * tones[0] = 'C1'
 * ...
 * tones[36] = 'C4'
 * ...
 * tones[69] = 'A' 440Mhz
 * ...
 * tone[127] = 'G11'
 *
 * @returns
 *
 * @private
 */
export const frequencies = [
    8.1757989156,
    8.6619572180,
    9.1770239974,
    9.7227182413,
    10.3008611535,
    10.9133822323,
    11.5623257097,
    12.2498573744,
    12.9782717994,
    13.75,
    14.5676175474,
    15.4338531643,

    16.3515978313,
    17.3239144361,
    18.3540479948,
    19.4454364826,
    20.6017223071,
    21.8267644646,
    23.1246514195,
    24.4997147489,
    25.9565435987,
    27.5,
    29.1352350949,
    30.8677063285,

    32.7031956626,
    34.6478288721,
    36.7080959897,
    38.8908729653,
    41.2034446141,
    43.6535289291,
    46.2493028390,
    48.9994294977,
    51.9130871975,
    55,
    58.2704701898,
    61.7354126570,

    65.4063913251,
    69.2956577442,
    73.4161919794,
    77.7817459305,
    82.4068892282,
    87.3070578583,
    92.4986056779,
    97.9988589954,
    103.8261743950,
    110,
    116.5409403795,
    123.4708253140,

    130.8127826503,
    138.5913154884,
    146.8323839587,
    155.5634918610,
    164.8137784564,
    174.6141157165,
    184.9972113558,
    195.9977179909,
    207.6523487900,
    220,
    233.0818807590,
    246.9416506281,

    261.6255653006,
    277.1826309769,
    293.6647679174,
    311.1269837221,
    329.6275569129,
    349.2282314330,
    369.9944227116,
    391.9954359817,
    415.3046975799,
    440,
    466.1637615181,
    493.8833012561,

    523.2511306012,
    554.3652619537,
    587.3295358348,
    622.2539674442,
    659.2551138257,
    698.4564628660,
    739.9888454233,
    783.9908719635,
    830.6093951599,
    880,
    932.3275230362,
    987.7666025122,

    1046.5022612024,
    1108.7305239075,
    1174.6590716696,
    1244.5079348883,
    1318.5102276515,
    1396.9129257320,
    1479.9776908465,
    1567.9817439270,
    1661.2187903198,
    1760,
    1864.6550460724,
    1975.5332050245,

    2093.0045224048,
    2217.4610478150,
    2349.3181433393,
    2489.0158697766,
    2637.0204553030,
    2793.8258514640,
    2959.9553816931,
    3135.9634878540,
    3322.4375806396,
    3520,
    3729.3100921447,
    3951.0664100490,

    4186.0090448096,
    4434.9220956300,
    4698.6362866785,
    4978.0317395533,
    5274.0409106059,
    5587.6517029281,
    5919.9107633862,
    6271.9269757080,
    6644.8751612791,
    7040,
    7458.6201842894,
    7902.1328200980,

    8372.0180896192,
    8869.8441912599,
    9397.2725733570,
    9956.0634791066,
    10548.0818212118,
    11175.3034058561,
    11839.8215267723,
    12543.8539514160,
];

/**
 * Tone grid with visible zero based index
 *
 * C1  0    8.1757989156    12    16.3515978313    24    32.7031956626
 * Db  1    8.6619572180    13    17.3239144361    25    34.6478288721
 * D   2    9.1770239974    14    18.3540479948    26    36.7080959897
 * Eb  3    9.7227182413    15    19.4454364826    27    38.8908729653
 * E   4   10.3008611535    16    20.6017223071    28    41.2034446141
 * F   5   10.9133822323    17    21.8267644646    29    43.6535289291
 * Gb  6   11.5623257097    18    23.1246514195    30    46.2493028390
 * G   7   12.2498573744    19    24.4997147489    31    48.9994294977
 * Ab  8   12.9782717994    20    25.9565435987    32    51.9130871975
 * A   9   13.7500000000    21    27.5000000000    33    55.0000000000
 * Bb  10  14.5676175474    22    29.1352350949    34    58.2704701898
 * B   11  15.4338531643    23    30.8677063285    35    61.7354126570
 *
 * C4  36  65.4063913251    48   130.8127826503    60   261.6255653006
 * Db  37  69.2956577442    49   138.5913154884    61   277.1826309769
 * D   38  73.4161919794    50   146.8323839587    62   293.6647679174
 * Eb  39  77.7817459305    51   155.5634918610    63   311.1269837221
 * E   40  82.4068892282    52   164.8137784564    64   329.6275569129
 * F   41  87.3070578583    53   174.6141157165    65   349.2282314330
 * Gb  42  92.4986056779    54   184.9972113558    66   369.9944227116
 * G   43  97.9988589954    55   195.9977179909    67   391.9954359817
 * Ab  44  103.8261743950   56   207.6523487900    68   415.3046975799
 * A   45  110.0000000000   57   220.0000000000    69   440.0000000000
 * Bb  46  116.5409403795   58   233.0818807590    70   466.1637615181
 * B   47  123.4708253140   59   246.9416506281    71   493.8833012561
 *
 * C7  72  523.2511306012   84  1046.5022612024    96  2093.0045224048
 * Db  73  554.3652619537   85  1108.7305239075    97  2217.4610478150
 * D   74  587.3295358348   86  1174.6590716696    98  2349.3181433393
 * Eb  75  622.2539674442   87  1244.5079348883    99  2489.0158697766
 * E   76  659.2551138257   88  1318.5102276515   100  2637.0204553030
 * F   77  698.4564628660   89  1396.9129257320   101  2793.8258514640
 * Gb  78  739.9888454233   90  1479.9776908465   102  2959.9553816931
 * G   79  783.9908719635   91  1567.9817439270   103  3135.9634878540
 * Ab  80  830.6093951599   92  1661.2187903198   104  3322.4375806396
 * A   81  880.0000000000   93  1760.0000000000   105  3520.0000000000
 * Bb  82  932.3275230362   94  1864.6550460724   106  3729.3100921447
 * B   83  987.7666025122   95  1975.5332050245   107  3951.0664100490

 * C10 108 4186.0090448096  120  8372.0180896192
 * Db  109 4434.9220956300  121  8869.8441912599
 * D   110 4698.6362866785  122  9397.2725733570
 * Eb  111 4978.0317395533  123  9956.0634791066
 * E   112 5274.0409106059  124 10548.0818212118
 * F   113 5587.6517029281  125 11175.3034058561
 * Gb  114 5919.9107633862  126 11839.8215267723
 * G   115 6271.9269757080  127 12543.8539514160
 * Ab  116 6644.8751612791
 * A   117 7040.0000000000
 * Bb  118 7458.6201842894
 * B   119 7902.1328200980
 */
