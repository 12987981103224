import styled from 'styled-components';
import {FormControlGroup} from './form-control-group';
import {BLACK, DIMMED} from '../app/const/color';

export const RecordedTone = styled(FormControlGroup)` 
    overflow: hidden;
   
    .container {
        padding-bottom: 1em
        overflow: hidden;
        float: left;
        
    }
    .instructions {
        text-align: left;
    }
    
    .tone {
        padding-left: 1em;
        padding-right: .2em;
        
        font-size: 2em;
        color: ${BLACK}; 
    }
    
    .duration {
        padding-left: .2em;
        padding-right: 1em;
    
        font-size: 1.2em;
        font-style: italic; 
        color: ${DIMMED};
    }
    
    .btn-clear {
        float: right;
        margin: 4px 1.2em;
    }
`;
