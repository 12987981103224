import styled from 'styled-components';
import {DIMMED} from '../app/const/color';

export const FormControlGroup = styled.div`    
  label {
    color: ${DIMMED};
  }
  
  span.hint {
    font-size: inherit;
    color: inherit;
    opacity: .6;
    font-style: italic;
  }
`;
