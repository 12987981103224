import React from 'react';
import {Button} from '@material-ui/core';

import Icon from './icon';

const size = "small";
/**
 * Generic function to render buttons
 *
 * @param label
 * @param color
 * @param props
 *
 * @returns {*}
 * @private
 */
const _btn = (label, color, props) => {
    return (
        <span>
				<Button
                    variant="contained"
                    disabled={props.disabled}
                    size={size}
                    color={color}
                    onClick={props.onClick}
                    className={props.className}
                >
					{label}
				</Button>
			</span>
    );
};

/**
 * To play the recorded sequence
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const BtnPlay = props => {
    const color = props.isPlaying ? 'primary' : 'default';

    return _btn(<Icon fontSize={size} name="play"/>, color, props);
};

/**
 * To stop the playing sequence
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const BtnStop = props => {
    return _btn(<Icon fontSize={size} name="stop"/>, 'default', props);
};

/**
 * To clear the notes of the recording
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const BtnClear = props => {
    return _btn(<Icon fontSize={size} name="delete"/>, 'default', props);
};

export const BtnSave = props => {
    return _btn(<Icon fontSize={size} name="save"/>, 'primary', props);
};

export const BtnArmed = props => {
    const color = props.armed ? 'secondary' : 'default';
    const {size} = props;

    return _btn(<Icon fontSize={size} name="record"/>, color, props);
};

export const BtnVolume = props => {
    const icon = props.on ? 'volume-on' : 'volume-off';
    const {size} = props;

    return _btn(<Icon fontSize={size} name={icon}/>, 'default', props);
};

export const BtnIncrease = props => {
    return _btn(<Icon fontSize={size} name="increase"/>, 'default', props);
};

export const BtnDecrease = props => {
    return _btn(<Icon fontSize={size} name="decrease"/>, 'default', props);
};
