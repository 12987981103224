import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Metronome, {MAX_TEMPO, MIN_TEMPO} from './index';

/**
 * Render prop to change tempo via slider
 *
 * The prop display a slider with a span to show the current tempo
 */
class MetronomeSlider extends Component {
    /**
     * To optionally set the initial tempo value
     *
     * If tempo is not set it defaults to the default prop of Metronome
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {tempo: props.tempo};
    }

    /**
     * To change the tempo based on the slider value
     *
     * @param event
     */
    handleTempoChange = event => {
        this.setState({
            tempo: parseInt(event.target.value)
        });

        this.props.setTempo(event.target.value);
    };

    /**
     * To render the slider with its displayed value
     *
     * active: the state of the metronome
     * tempo: the tempo of the metronome, note that this is the used value
     *                rather than the current setting of the state.
     * toggle: the method to toggle the state of the metronome
     */
    render() {
        return (
            <Metronome
                active={this.props.active}
                tempo={this.state.tempo}
                setTempo={this.props.setTempo}
            >
                <Fragment>
                    <input
                        type="range"
                        min={MIN_TEMPO}
                        max={MAX_TEMPO}
                        step={2}
                        onChange={this.handleTempoChange}
                    />

                    <span disabled={!this.props.active}>{this.state.tempo}</span>
                </Fragment>
            </Metronome>
        );
    }
}

MetronomeSlider.propTypes = {
    active: PropTypes.bool,
    tempo: PropTypes.number,
    setTempo: PropTypes.func.isRequired
};

export default MetronomeSlider;
