import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {USA_DATE} from '../../config/constants';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
});

function SequenceList(props) {
    const {classes, sequences} = props;

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Created</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {sequences.map(sequence => (
                        <TableRow key={sequence.id}>
                            <TableCell component="th" scope="row">
                                {sequence.name}
                            </TableCell>

                            <TableCell>{moment.unix(sequence.createdAt / 1000).format(USA_DATE)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

SequenceList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SequenceList);