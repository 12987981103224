import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {TextField, Button, Grid, Typography} from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../styles';

export class StepThreeInstrument extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, errors, handleChange, classes, gridWidth} = this.props;

        /**
         * To disable the continue button until for is valid
         *
         * @returns {boolean}
         */
        const incomplete = () => {
            const complete = values.instrument && errors.instrument === ''
                && values.bio && errors.bio === '';

            return ! complete;
        }

        return (
            <Grid
                justify="center"
                container
                direction="column"
                alignItems="center"
                className={classes.container}
            >
                <Typography variant="h5">Biography</Typography>
                <Grid item xs={gridWidth}>
                    <TextField
                        name="instrument"
                        label="Instrument"
                        placeholder="What instrument do you play?"
                        defaultValue={values.instrument}
                        className={classes.textArea}
                        onChange={handleChange('instrument')}
                        margin="normal"
                        error={errors.instrument !== ''}
                        helperText={errors.instrument}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Biography"
                        placeholder="Please tell use more about you as a musician!"
                        multiline
                        rows="10"
                        defaultValue={values.bio}
                        className={classes.textArea}
                        onChange={handleChange('bio')}
                        margin="normal"
                        error={errors.bio !== ''}
                        helperText={errors.bio}
                    />
                </Grid>

                <Grid item xs={gridWidth} className={classes.buttons}>
                    <Button variant="contained" color="default" onClick={this.back}>
                        <NavigateBefore/> Back
                    </Button>

                    <Button variant="contained" color="primary" onClick={this.continue}
                            disabled={incomplete()}>
                        Continue <NavigateNext/>
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

StepThreeInstrument.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StepThreeInstrument);
