export const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },

    table: {
        minWidth: 700,
        width: 800,
        margin: '0 auto',
    },
});