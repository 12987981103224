import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Grid, TextField, Button, Typography} from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../styles';

export class StepOneCredentials extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    render() {
        const {classes, values, errors, handleChange, gridWidth} = this.props;

        /**
         * To disable the continue button until for is valid
         *
         * @returns {boolean}
         */
        const incomplete = () => {
            const complete = values.username && errors.username === ''
                && values.password && errors.password === ''
                && values.passwordConfirm && errors.passwordConfirm === '';

            return ! complete;
        }

        return (
            <Grid
                className={classes.container}
                justify="center"
                container
                direction="column"
                alignItems="center"
            >
                <Typography variant="h5">Credentials</Typography>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Email"
                        placeholder="Email"
                        defaultValue={values.username}
                        className={classes.textField}
                        onChange={handleChange('username')}
                        margin="normal"
                        error={errors.username !== ''}
                        helperText={errors.username}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Password"
                        type="password"
                        placeholder="Password"
                        className={classes.textField}
                        onChange={handleChange('password')}
                        margin="normal"
                        error={errors.password !== ''}
                        helperText={errors.password}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Password Confirmation"
                        type="password"
                        placeholder="Password Confirmation"
                        className={classes.textField}
                        onChange={handleChange('passwordConfirm')}
                        margin="normal"
                        disabled={values.password === '' || errors.password !== ''}
                        error={errors.passwordConfirm !== ''}
                        helperText={errors.passwordConfirm}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <Button variant="contained" color="primary" onClick={this.continue}
                            disabled={incomplete()}>
                        Continue <NavigateNext/>
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

StepOneCredentials.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StepOneCredentials);
