import React from 'react';
import _ from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const SequenceSelect = props => {

    const items = () => (
        _.map(props.sequences, sequence => (
            <MenuItem key={sequence.id} value={sequence.id}>
                {sequence.name}
            </MenuItem>
        ))
    );

    return (
        <FormControl>
            <Select onChange={props.onChange}
                    value={props.selected}
                    autoWidth>

                <MenuItem key={0} value="0">
                    <em>Select Sequence</em>
                </MenuItem>

                {items(props.sequences)}
            </Select>
        </FormControl>
    );
};

SequenceSelect.defaultProps = {
    selected: '0'
};

export default SequenceSelect;