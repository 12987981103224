import React, {Component} from 'react';

class SequenceActive extends Component {
    renderTones(sequence) {
        return sequence.map((tone, index) => {
            return <span key={index}>[{tone}] </span>;
        });
    }

    render() {
        if (!this.props.sequence) {
            return <div>Select a sequence to display.</div>;
        }

        return (
            <div>
                <h1>{this.props.sequence.name}</h1>
                <h2>{this.props.sequence.description}</h2>
                <div>{this.renderTones(this.props.sequence.sequence)}</div>
            </div>
        );
    }
}

export default SequenceActive;
