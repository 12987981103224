import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import {GRAPHQL_URL} from './config/constants';
import Routes from './app/routes';

import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';

const client = new ApolloClient({
    uri: GRAPHQL_URL,
    clientState: {
        defaults: {},
        resolvers: {}
    },
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Routes/>
    </ApolloProvider>,
    document.getElementById('app')
);
