import styled from 'styled-components';
import {FormControlGroup} from './form-control-group';
import {BLACK, DIMMED} from '../app/const/color';

export const PianoControl = styled(FormControlGroup)`
span {
    font-size: 1.8em;
    color: ${BLACK};
}

sup {
    font-size: .5em;
    font-style: italic;
    color: ${DIMMED};
}
.tempo-control {
    padding-top: 20px;
}

.tempo-label {
    font-size: 1.5em;
    padding-top: 4px;
}

.tempo-volume {
    top: 2px;
    position: relative;
}
.btn-armed {
    background: #ff3333;
}
.btn-armed svg {
    color: #fff;
`;
