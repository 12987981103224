export const styles = theme => ({
    root: {
        flexGrow: 1,
        minSize: 320,
    },

    grow: {
        flexGrow: 1
    },

    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },

    content: {
        marginTop: '2rem',
        maxWidth: 756,
        paddingBottom: 64,
        margin: '0 auto',
    },

    footer: {
        top: 'auto',
        bottom: 0,
        textAlign: 'center',
    }
});
