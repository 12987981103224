import React, {Component, Fragment} from 'react';
import {gql} from 'apollo-boost';
import {graphql} from 'react-apollo';
import _ from 'lodash';

const getBooksQuery = gql`
    {
        sequences {
            id
            name
            tones
        }
    }
`;

class SequenceList extends Component {
    renderList = sequences => {
        return _.map(sequences, sequence => {
            return (
                <li key={sequence.id} id={sequence.id} className="sequence">
                    {sequence.name}
                </li>
            );
        });
    };

    render() {
        if (this.props.loading) {
            return <div>Loading...</div>;
        }

        return (
            <Fragment>
                <ul>{this.renderList(this.props.data.sequences)}</ul>
            </Fragment>
        );
    }
}

export default graphql(getBooksQuery)(SequenceList);
