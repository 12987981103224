import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {isAuth} from '../config/auth';
import PropTypes from 'prop-types';
import {compose} from 'recompose';

import Grid from '@material-ui/core/Grid';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';

import Footer from './layout/footer';
import {styles} from './styles';
import Settings from '@material-ui/icons/Settings';
import ThemeContextProvider from '../config/context/theme';

import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#009688',
        },
        secondary: {
            main: '#cddc39'
        },
        error: red
    },
});

class Layout extends Component {

  rightButtons = () => {
    if (isAuth()) {
      return (
          <Fragment>
            {/*<Button color="inherit" key="Profile"*/}
            {/*        component={Link} to="/user/profile"><Settings /></Button>*/}
            <Button color="inherit" key="Login"
                    component={Link} to="/user/logout">Logout</Button>
          </Fragment>

            );

        } else {
            return (<Button color="inherit" key="Login"
                            component={Link} to="/user/login">Login</Button>);
        }
    };

    render() {
        const {classes} = this.props;

        return (
            <ThemeContextProvider>


                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>

                    <AppBar position="static">
                        <Toolbar>
                            <IconButton className={classes.menuButton} color="inherit"
                                        aria-label="Menu">
                                <MenuIcon/>
                            </IconButton>

                            <Typography variant="h6" color="inherit"
                                        className={classes.grow}>
                                Metrotone
                            </Typography>

                            <div className={classNames(classes.appBarButtons,
                                classes.menuButton)}>
                                {this.rightButtons()}
                            </div>
                        </Toolbar>
                    </AppBar>

                    <Grid className={classes.content} container direction="row"
                          justify="center">
                        <Grid item xs={10}>
                            {this.props.children}
                        </Grid>
                    </Grid>

                    <Footer pathname={this.props.location.pathname}/>
                </MuiThemeProvider>
            </ThemeContextProvider>
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(
    withRouter,
    withStyles(styles, {withTheme: true}),
)(Layout);