import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import JwtDecode from 'jwt-decode';

/**
 * To check for the a current JWT Token
 *
 * Note:
 * The server issues the value in unix time but javascript in milliseconds
 *
 *    Math.round(auth.exp - new Date().getTime() / 1000), 'secs')
 *
 * @returns {boolean}
 */
export const isAuth = () => {
    let auth = localStorage.getItem('auth');

    let valid = false;

    if (auth) {
        auth = JSON.parse(auth);
        try {
            auth = JwtDecode(auth);

            valid = auth.exp * 1000 > new Date().getTime();
        } catch (e) {
        }
    }

    return valid;
};

/**
 * To logout user
 */
export const revokeAuth = () => {
    localStorage.removeItem('auth');
};

/**
 * To protect a route by redirecting user to login unless authenticated
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
const Auth = ({component: Component, ...routeProps}) => {
    return (
        <Route {...routeProps} render={props => (
            isAuth()
                ? <Component {...props} />
                : <Redirect to={{pathname: '/user/login'}}/>
        )}/>
    )
};

export default Auth;
