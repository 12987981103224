import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {KeyboardShortcuts, MidiNumbers} from 'react-piano';
import 'react-piano/dist/styles.css';
import PianoWithRecord from './piano';
import Metronome, {DEFAULT_TEMPO} from '../metronome';


import {BtnPlay, BtnStop, BtnSave, BtnArmed, BtnVolume} from './ui/button';
import RecordedTones from './component/recorded-tones';

import SliderTempo from './ui/slider';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core';
import styles, {GRID_SPACING} from '../style/grid';

import Input from '@material-ui/core/Input';
import {PianoControl} from "../style/piano-control";

export const MIN_NAME_LEN = 6;

class PianoRecord extends React.Component {
    constructor(props) {
        super(props);

        this.noteRange = {
            first: MidiNumbers.fromNote('c3'),
            last: MidiNumbers.fromNote('b3')
        };

        this.state = {
            armed: false,
            noteDuration: 1,
            tempo: DEFAULT_TEMPO,
            metronomeSilent: false,
            keyboardShortcuts: [],
            name: '',
            recording: {
                mode: 'RECORDING',
                tones: [],
                currentEvents: []
            }
        };

        this.nextTone = 0;
        this.currentTick = 1;

        // Skips the first note after refresh to prevent doucple ticking
        this.skipOne = false;

        // Toggled on and off for each tick of the metronome
        this.tempoDisplayToggle = true;
    }

    componentDidMount() {
        this.enableKeyboardShortcuts();
    }

    disableKeyboardShortcuts = () => {
        this.setState({keyboardShortcuts: []});
    };

    enableKeyboardShortcuts = () => {
        this.setState({
            keyboardShortcuts: KeyboardShortcuts.create({
                firstNote: this.noteRange.first,
                lastNote: this.noteRange.last,
                keyboardConfig: KeyboardShortcuts.HOME_ROW
            })
        });
    };

    /**
     * To setup the state of the reuse the setNoteDuration
     */
    componentWillMount = () => {
        this.setNoteDuration(DEFAULT_TEMPO);
    };

    /**
     * To append a note to the recording
     *
     * @param value
     */
    setRecording = value => {
        this.setState({
            recording: Object.assign({}, this.state.recording, value)
        });
    };

    /**
     * To increment the number of ticks of a tone.
     *
     * This value determines how long a pitch is played
     *
     * @param toneIndex
     */
    addTick = toneIndex => {
        if (toneIndex >= 0) {
            let recording = this.state.recording;
            recording.tones[toneIndex].ticks++;

            this.setState({recording});
        }
    };

    /**
     * To set the next tone to play in the sequence
     *
     * Only increment to the next tone when the tick is set to zero.
     *
     * @returns {number}
     */
    setNextTone = () => {
        if (this.currentTick < this.state.recording.tones[this.nextTone].ticks) {
            this.currentTick++;
        } else {
            this.currentTick = 1;

            if (++this.nextTone === this.state.recording.tones.length) {
                this.nextTone = 0;
            }
        }
    }

    /**
     * To play the next note in the recording.
     *
     * This function is used by the metronome for every tick.
     *
     * If playing mode then the next event is set to the recoding if any
     *
     * If we are in recording mode then the previously recorded tone
     * needs to be incremented.
     */
    playNextTone = () => {
        const mode = this.state.recording.mode;

        if (this.skipOne) {
            this.skipOne = false;
        } else if (mode === 'PLAYING') {
            if (this.state.recording.tones.length) {
                this.setNextTone();

                this.setRecording({
                    currentEvents: [this.state.recording.tones[this.nextTone]]
                });
            }
        } else if (mode === 'RECORDING') {
            const prevToneIndex = this.state.recording.tones.length - 1;

            this.addTick(prevToneIndex);
        }

        this.tempoDisplayToggle = !this.tempoDisplayToggle;
    };

    /**
     * Playback driver
     */
    onClickPlay = () => {
        this.setRecording({
            mode: 'PLAYING'
        });

        this.setState({
            armed: true
        });

        this.nextTone = 0;
        this.currentTick = 1;
    };

    /**
     * To stop the play back and reset state
     *
     * The end of the playback is determined in onClickPLay.
     * This is a reset function at the end of the playback or when stop is clicked.
     */
    onClickStop = () => {
        this.setRecording({
            mode: 'RECORDING',
            currentEvents: []
        });

        this.setState({
            armed: false
        });

        this.tempoDisplayToggle = true;
    };

    onClickClear = () => {
        this.onClickStop();

        this.setRecording({
            tones: [],
            mode: 'RECORDING',
            currentEvents: []
        });
    };

    onChangeArmed = () => {
        if (this.state.armed) {
            this.onClickStop();
        } else {
            this.setState({armed: true});
        }
    };

    onClickSave = () => {
        console.log('Saving');
    };

    onChangeName = input => {
        this.setState({name: input.target.value});
    };

    setNoteDuration = tempo => {
        this.setState({noteDuration: 60 / tempo});
    };

    /**
     * To change the tempo based on the slider value
     *
     * @param tempo
     */
    onChangeTempo = tempo => {
        this.setState({
            tempo: parseInt(tempo)
        });

        this.skipOne = true;
    };

    /**
     * To toggle the metronome sound on or off
     */
    onChangeMetronomeSound = () => {
        this.setState({metronomeSilent: !this.state.metronomeSilent});
    };

    /**
     * To render the metronome slider with tempo label
     *
     * @returns {*}
     */
    metronome = () => {
        const {classes} = this.props;

        return (
            <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12}>
                    <PianoControl>
                        <label>Tempo</label>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <SliderTempo
                                        tempo={DEFAULT_TEMPO}
                                        classes={classes}
                                        disabled={this.state.armed}
                                        onChangeTempo={this.onChangeTempo}
                                        className="tempo-control"
                                    />
                                </Grid>

                                <Grid item xs={2} className="tempo-label">
                                    {this.state.tempo}
                                </Grid>

                                <Grid item xs={2} className="volume-icon">
                                    <BtnVolume disabled={this.state.metronomeSilent}
                                               className="tempo-volume"
                                               onClick={this.onChangeMetronomeSound}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </PianoControl>
                </Grid>
            </Grid>
        );
    };

    /**
     * To render the piano keyboard in a single row
     *
     * @returns {*}
     */
    piano = () => {
        const {classes} = this.props;

        return (
            <PianoControl>
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        {this._piano()}
                    </Grid>
                </Paper>
            </PianoControl>
        );
    };

    /**
     * To render piano as a render prop to be controlled by the metronome
     *
     * @returns {*}
     * @private
     */
    _piano = () => {
        return (
            <Metronome
                active={this.state.armed}
                tempo={this.state.tempo}
                onTick={this.playNextTone}
                silent={this.state.metronomeSilent}
            >
                <PianoWithRecord
                    noteRange={this.noteRange}
                    noteDuration={this.state.noteDuration}
                    armed={this.state.armed}
                    recording={this.state.recording}
                    setRecording={this.setRecording}
                    keyboardShortcuts={this.state.keyboardShortcuts}
                />
            </Metronome>
        );
    };

    /**
     * To display the tones of the current recording session
     *
     * @returns {JSX.Element}
     */
    recordedTones = () => {
        const {classes} = this.props;
        const disabled = this.state.recording.tones.length === 0;

        return (
            <Grid item xs={12}>
                <PianoControl>
                    <label>Recording</label>
                    <Paper className={classes.paper}>
                        <RecordedTones
                            onClickClear={disabled ? false : () => this.onClickClear()}
                            tones={this.state.recording.tones}>
                        </RecordedTones>
                    </Paper>
                </PianoControl>
            </Grid>
        );
    }

    /**
     * Controls to operate the recorder
     *
     * @returns {*}
     */
    recorder = () => {
        const {classes} = this.props;
        const disabled = this.state.armed || this.state.recording.tones.length === 0;

        return (
            <Grid container direction="row" spacing={GRID_SPACING}>
                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Save Recording</label>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={8} sm={7}>
                                    <Input onChange={this.onChangeName}
                                           onFocus={this.disableKeyboardShortcuts}
                                           onBlur={this.enableKeyboardShortcuts}
                                           className={classes.input}
                                           placeholder="Name (6 letter min.)"/>
                                </Grid>

                                <Grid item xs={4} sm={5}>
                                    <BtnSave onClick={this.onClickSave}
                                             color="primary"
                                             disabled={this.state.name.length < MIN_NAME_LEN}
                                             className={classes.btnRecord}/>
                                </Grid>

                            </Grid>
                        </Paper>
                    </PianoControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Recorder Controls</label>
                        <Paper className={classes.paper}>
                            <BtnStop onClick={this.onClickStop}
                                     disabled={this.state.recording.mode !== 'PLAYING'}
                                     className={classes.btnRecord}/>

                            <BtnPlay disabled={disabled}
                                     onClick={this.onClickPlay}
                                     className={classes.btnRecord}/>

                            <BtnArmed
                                className={this.state.armed && "btn-armed"}
                                disabled={this.state.recording.mode === 'PLAYING'}
                                armed={this.state.armed}
                                onClick={this.onChangeArmed}>
                            </BtnArmed>

                        </Paper>
                    </PianoControl>
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <Fragment>
                {this.recorder()}

                {this.piano()}

                {this.metronome()}

                {this.recordedTones()}
            </Fragment>
        );
    }
}

PianoRecord.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PianoRecord);
