import React, {Component} from 'react';
import {
    frequencies, MIN_MIDI_NUMBER, MAX_MIDI_NUMBER,
    MAX_OCTAVE_OFFSET, MIN_OCTAVE_OFFSET,
} from './tone';

export const MIN_FREQUENCY_OFFSET = -25;
export const MAX_FREQUENCY_OFFSET = 20;
export const FREQUENCY_OFFSET_INCREMENT = 1;

export const MAX_GAIN = 1;
export const MIN_GAIN = 0;
export const GAIN_INCREMENT = .1;

class Synth extends Component {
    constructor(props) {
        super(props);

        this.paused = false;
        this.syncTone = false;

        this.ready = true;
        this.playing = false;

        const AudioContext = window.AudioContext || window.webkitAudioContext;

        this.audio = new AudioContext();

        this.oscillator = null;
        this.gainNode = null;
        this.gain = .5;

        this.rampIncrement = 0.01;

        this.time = {
            stop: .2,
            start: .15,
        };

    this.octaveOffset = 0;

    this.frequencyOffset = 0;

        this.midiNumber = null;

        this.frequencies = frequencies;

        if (this.props.octaveOffsetUp) {
            this.octaveOffsetUp = this.props.octaveOffsetUp;
        }

        if (this.props.octaveOffsetDown) {
            this.octaveOffsetDown = this.props.octaveOffsetDown;
        }
    }

    /**
     * To load al sequences via redux
     */
    componentDidMount = () => {
        document.addEventListener('keydown', this.handleShortcuts);
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleShortcuts);
    }

    /**
     * To handle keyboard actions.
     *
     * This function needs to be moved to its own component.
     *
     * The current functionality is to toggle the ticks and tones independently.
     * If both are started then pressing the key space key will toggle both
     * on or off.
     *
     * To return to only toggling either one of them the las sounding tone key must
     * be pressed to stop it or the metronome play button must be clicked.
     *
     * Space: 32
     *
     * Left Arrow: 37
     *
     * Up Arrow: 38
     *
     * Right Arrow: 39
     *
     * Down Arrow: 40
     * ReactPiano__Key--active
     */
    handleSpaceBar = event => {
        event.preventDefault();

        if (this.syncTone || this.props.syncTicking) {
            if (this.syncTone) {
                if (this.paused) {
                    this.paused = false;
                    this.playNote(this.midiNumber);
                } else {
                    this.paused = true;
                    this.stop();
                }
            }

            if (this.props.syncTicking) {
                let isTicking = !this.props.isTicking;

                if (this.syncTone) {
                    isTicking = !this.paused;
                }

                this.props.setTicking(isTicking);
            }
        }
    };

    /**
     * To shift up the current pitch semitone
     *
     * @param event
     */
    handleDownArrow = event => {
        event.preventDefault();

        if (this._midiNumber() >= MIN_MIDI_NUMBER) {
            if (this.playing) {
                if (event.shiftKey) {
                    this.setOctaveOffset(this.octaveOffset - 1);
                } else if (event.ctrlKey) {
                    this.setFrequencyOffset(this.frequencyOffset - 1);
                } else {
                    const next = this.midiNumber - 1;

                    if (this.midiNumber % 12 === 0) {
                        this.midiNumber = next + 12;
                        this.octaveOffsetDown(this.octaveOffset - 1);
                    } else {
                        this.playNote(next);
                    }
                }
            } else {
                this.midiNumber--;
            }
        }
    };

    /**
     * To shift down the current pitch semitone
     *
     * If the note about to be played is C then the octave
     * of the octave prop needs to be adjusted.
     *
     * @param event
     */
    handleUpArrow = event => {
        event.preventDefault();

        if (this._midiNumber() <= MAX_MIDI_NUMBER) {
            if (this.playing) {
                if (event.shiftKey) {
                    this.setOctaveOffset(this.octaveOffset + 1);
                } else if (event.ctrlKey) {
                    this.setFrequencyOffset(this.frequencyOffset + 1);
                } else {
                    const next = this.midiNumber + 1;

                    if (next % 12 === 0) {
                        this.midiNumber = next - 12;
                        this.octaveOffsetUp(this.octaveOffset + 1);
                    } else {
                        this.playNote(next);
                    }
                }
            } else {
                this.midiNumber++;
            }
        }
    };

    /**
     * To route the space bar and up and dow arrow keys
     *
     * space = 32 - stop and start the metronome and playig note
     * up = 38 - chromatic scale up and by octave with meta key
     * right = 40 - chromatic scale down and by octave with meta key
     *
     * @param event
     */
    handleShortcuts = event => {
        if (event.keyCode === 32) {
            this.handleSpaceBar(event);

        } else if (event.keyCode === 38) {
            this.handleUpArrow(event);

        } else if (event.keyCode === 40) {
            this.handleDownArrow(event);
        }
    };

    /**
     * Handler for the piano and arrow keys to generate sound when played
     *
     * @param midiNumber
     */
    playNote = midiNumber => {
        if (this.playing && !this.paused
            && this.midiNumber === midiNumber
        ) {
            this.paused = true;
            this.stop();

        } else if (this.ready) {
            this.paused = false;

            this.stop();

            this.ready = false;
            this.playing = true;

            this.start(midiNumber);

            this.ready = true;

            this.syncTone = !this.paused;
        }
    };

    /**
     * Triggered when a piano key is released
     *
     * The sound is always sustains therefore no action is taken
     *
     * @param midiNumber
     */
    stopNote = midiNumber => {
    };

    /**
     * To stop the current pitch
     *
     * The pitch is incrementally stopped to prevent pops. The start
     * counterpart also plays with a small delay to prevent overlapping
     */
    stop = () => {
        if (this.ready && this.playing) {
            this.ready = false;
            const time = this.audio.currentTime + this.time.stop;

            this.gainNode.gain.exponentialRampToValueAtTime(this.rampIncrement, time);

            this.oscillator.stop(time);

            this.ready = true;
            this.playing = false;
        }
    };

    /**
     * To start the sustained pitch
     *
     * The pitch is played after a small delay to make the transition
     * of stopping the previous smooth.
     *
     * @param midiNumber
     */
    start = midiNumber => {
        this.midiNumber = midiNumber;
        const time = this.audio.currentTime + this.time.start;

        this.oscillator = this.audio.createOscillator();
        this.gainNode = this.audio.createGain();
        this.gainNode.gain.setValueAtTime(this.gain, this.audio.currentTime);
        this.oscillator.connect(this.gainNode);
        this.gainNode.connect(this.audio.destination);
        this.oscillator.type = 'sine';

        this.setFrequency();

        this.oscillator.start(time);
    };

    /**
     * To set the frequency of the note
     */
    setFrequency = () => {
        const midiNumber = this._midiNumber();

        this.oscillator.frequency.value = this.frequencies[midiNumber] +
            this.frequencyOffset;
    };

    /**
     * To calculate the effective midi number with the octave offset
     *
     * @returns {*}
     * @private
     */
    _midiNumber = () => this.midiNumber + (this.octaveOffset * 12);

    stopAllNotes = () => {
        this.stop();

        return true;
    };

    /**
     * To set the volume of the tone
     *
     * The volume can be adjusted while the pitch is playing
     *
     * @param gain
     */
    setGain = gain => {
        if (gain >= MIN_GAIN
            && gain <= MAX_GAIN
        ) {
            this.gain = gain;

            if (this.playing) {
                this.gainNode.gain.setValueAtTime(this.gain, this.audio.currentTime);
            }
        }
    };

    /**
     * To set the intonation offset of the tone
     *
     * @param offset
     */
    setFrequencyOffset = offset => {
        if (offset >= MIN_FREQUENCY_OFFSET
            && offset <= MAX_FREQUENCY_OFFSET
        ) {
            this.frequencyOffset = offset;

            if (this.playing) {
                this.setFrequency();
            }
        }
    };

    /**
     * To adjust the current octave if within range
     *
     * The pitch is only adjusted if in playing mode, otherwise, the next
     * time a pitch is played it will account for the new octave value
     *
     * @param offset
     */
    setOctaveOffset = offset => {
        if (offset >= MIN_OCTAVE_OFFSET
            && offset <= MAX_OCTAVE_OFFSET
        ) {
            this.octaveOffset = offset;

            if (this.playing) {
                this.setFrequency();
            }
        }
    };

    /**
     * To provide a sound generator as a render prop
     *
     * @returns {*}
     */
    render() {
        if (this.props.stop) {
            this.stop();
        } else {
            this.setGain(this.props.gain);
            this.setFrequencyOffset(this.props.frequencyOffset);
            this.setOctaveOffset(this.props.octaveOffset);
        }

        return this.props.render
            ? this.props.render({
                isLoading: false,
                playNote: this.playNote,
                stopNote: this.stopNote,
                stopAllNotes: this.stopAllNotes,
            })
            : <div/>;
    }
}

export default Synth;
