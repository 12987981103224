import React from 'react';
import Slider from '@material-ui/core/Slider';
import {MAX_TEMPO, MIN_TEMPO} from '../../metronome';

class SliderTempo extends React.Component {
    state = {
        value: this.props.tempo,
    };

    handleChange = (event, value) => {
        this.setState({value});

        this.props.onChangeTempo(value);
    };

    render() {
        const {classes} = this.props;

        return (
            <Slider
                disabled={this.props.disabled}
                step={2}
                min={MIN_TEMPO}
                max={MAX_TEMPO}
                classes={classes}
                value={this.state.value}
                aria-labelledby="label"
                className="tempo-control"
                onChange={this.handleChange}
            />
        );
    }
}

export default SliderTempo;
