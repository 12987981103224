import React, {Component} from 'react';
import UserForm from './create';
import {Query} from 'react-apollo';
import {USER_GQL} from '../gql/query';

class Update extends Component {
    render() {
        const {id} = this.props.match.params;

        return (
            <Query
                query={USER_GQL}
                variables={{id: id}}
            >
                {({data, loading}) => {
                    if (loading) return 'Loading...';

                    return <UserForm user={data.user}/>;
                }}
            </Query>
        );
    }
}

export default Update;
