import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import RecordIcon from '@material-ui/icons/Straighten';
import PlaybackIcon from '@material-ui/icons/VerticalSplit';
import MetrotoneIcon from '@material-ui/icons/Tune';
import {isAuth} from '../../config/auth';
import {styles} from '../styles';

class Footer extends Component {
    render() {
        if (!isAuth()) return <div></div>;

        const {classes} = this.props;

        return (
            <AppBar position="fixed" color="default" className={classes.footer}>
                <Paper square id="footer">
                    <Button component={Link}
                            to="/piano/playback"
                            className={classes.button}>
                        <PlaybackIcon fontSize="large"/>
                    </Button>

                    <Button component={Link}
                            to="/piano/record"
                            className={classes.button}>
                        <MetrotoneIcon fontSize="large"/>
                    </Button>

                    <Button component={Link}
                            to="/sequence/search"
                            className={classes.button}>
                        <RecordIcon fontSize="large"/>
                    </Button>
                </Paper>
            </AppBar>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);