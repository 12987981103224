import React, {Component} from 'react';

import StepOneCredentials from './step-one-credentials';
import StepTwoDetails from './step-two-details';
import StepThreeInstrument from './step-three-instrument';
import StepFourConfirm from './step-four-confirm';
import StepFiveSuccess from './step-five-success';
import {validate} from './validate';

const GRID_WIDTH_SMALL = 6;
const GRID_WIDTH_LARGE = 10;

/**
 * To set the values required by the user create schema
 *
 * The id is only used for the userUpdateInput
 *
 * @param user
 * @returns object
 */
export const setSchemaInputValues = user => {
    const values = {
        username: user ? user.username : '',
        password: user ? user.password : '',
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        city: user ? user.city : '',
        state: user ? user.state : '',
        country: user ? user.country : '',
        instrument: user ? user.instrument : '',
        bio: user ? user.bio : '',
    }

    if (user && user.id) {
        values.id = user.id;
    }

    return values;
}

class Container extends Component {
    constructor(props) {
        super(props);

        const {user} = props;

        this.state = setSchemaInputValues(user);
        this.state.step = 1;

        this.state.errors = setSchemaInputValues();

        this.state.errors.passwordConfirm = '';
        Object.keys(this.state.errors).forEach(field => {
            this.state.errors[field] = ''
        });
    }

    nextStep = () => {
        const {step} = this.state;

        this.setState({
            step: step + 1,
        });
    };

    prevStep = () => {
        const {step} = this.state;

        this.setState({
            step: step - 1,
        });
    };

    /**
     * To set or clear the error of each form field
     *
     * @param field
     * @param error
     */
    updateErrors = (field, error) => {
        this.setState({
            errors: {...this.state.errors, [field]: error}
        })
    }

    /**
     * To validate and set the form values
     *
     * @param field
     * @returns {(function(*): void)|*}
     */
    handleChange = field => e => {
        let error;
        let value = e.target.value;

        if (field === 'passwordConfirm') {
            error = validate(field, value, this.state.password);
        } else {
            error = validate(field, value);
        }

        this.updateErrors(field, error ? error : '');

        this.setState({[field]: value.trim()});
    };

    render() {
        switch (this.state.step) {
            case 1:
                return (
                    <StepOneCredentials
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        errors={this.state.errors}
                        gridWidth={GRID_WIDTH_SMALL}
                    />
                );
            case 2:
                return (
                    <StepTwoDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        errors={this.state.errors}
                        gridWidth={GRID_WIDTH_SMALL}
                    />
                );
            case 3:
                return (
                    <StepThreeInstrument
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        errors={this.state.errors}
                        gridWidth={GRID_WIDTH_LARGE}
                    />
                );
            case 4:
                return (
                    <StepFourConfirm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        values={this.state}
                        errors={this.state.errors}
                        gridWidth={GRID_WIDTH_LARGE}
                    />
                );
            case 5:
                return <StepFiveSuccess values={this.state}/>;

            default:
                return false;
        }
    }
}

export default Container;
