import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Grid, TextField, Button, Typography} from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../styles';

export class StepTwoDetails extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    render() {
        const {classes, values, errors, handleChange, gridWidth} = this.props;

        /**
         * To disable the continue button until for is valid
         *
         * @returns {boolean}
         */
        const incomplete = () => {
            const complete = values.firstName && errors.firstName === ''
                && values.lastName && errors.lastName === ''
                && values.city && errors.city === ''
                && values.state && errors.state === ''
                && values.country && errors.country === '';

            return ! complete;
        }

        return (
            <Grid
                className={classes.container}
                justify="center"
                container
                direction="column"
                alignItems="center"
            >
                <Typography variant="h5">User Details</Typography>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="First Name"
                        placeholder="First Name"
                        defaultValue={values.firstName}
                        className={classes.textField}
                        onChange={handleChange('firstName')}
                        margin="normal"
                        error={errors.firstName !== ''}
                        helperText={errors.firstName}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Last Name"
                        placeholder="Last Name"
                        defaultValue={values.lastName}
                        className={classes.textField}
                        onChange={handleChange('lastName')}
                        margin="normal"
                        error={errors.lastName !== ''}
                        helperText={errors.lastName}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="City"
                        placeholder="City"
                        defaultValue={values.city}
                        className={classes.textField}
                        onChange={handleChange('city')}
                        margin="normal"
                        error={errors.city !== ''}
                        helperText={errors.city}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="State"
                        placeholder="State"
                        defaultValue={values.state}
                        className={classes.textField}
                        onChange={handleChange('state')}
                        margin="normal"
                        error={errors.state !== ''}
                        helperText={errors.state}
                    />
                </Grid>

                <Grid item xs={gridWidth}>
                    <TextField
                        label="Country"
                        placeholder="Country"
                        defaultValue={values.country}
                        className={classes.textField}
                        onChange={handleChange('country')}
                        margin="normal"
                        error={errors.country !== ''}
                        helperText={errors.country}
                    />
                </Grid>

                <Grid item xs={gridWidth} className={classes.buttons}>
                    <Button variant="contained" color="default" onClick={this.back}>
                        <NavigateBefore/> Back
                    </Button>

                    <Button variant="contained" color="primary" onClick={this.continue}
                            disabled={incomplete()}>
                        Continue <NavigateNext/>
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

StepTwoDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StepTwoDetails);
