import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, TextField, Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles';
import {Link} from 'react-router-dom';
import {isAuth, revokeAuth} from '../../config/auth';
import {AUTH_URL} from '../../config/constants';
import {validate} from './create/validate';
class Login extends Component {
    constructor(props) {
        super(props);
        if (this.props.location.pathname === '/user/logout') {
            revokeAuth();
        }

        if (isAuth()) {
            this.props.history.push('/piano/playback');
        }

        this.state = {
            login: true,
            username: '',
            password: '',
            errors: {
                username: '',
                password: '',
            }
        };
    }

    /**
     * To set or clear the error of each form field
     *
     * @param field
     * @param error
     */
    updateErrors = (field, error) => {
        this.setState({
            errors: {...this.state.errors, [field]: error}
        })
    }

    /**
     * To login a user and store the token to local storage
     */
    loginUser = e => {
        e.preventDefault();

        const query = `username=${this.state.username}&password=${this.state.password}`;

        fetch(AUTH_URL + '?' + query, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(auth => {
                if (typeof (auth.message) === 'string') {
                    this.updateErrors('password', auth.message);
                } else {
                    localStorage.setItem('auth', JSON.stringify(auth));
                    this.props.history.push('/piano/playback');
                }
            })
            .catch(error => {
                console.log(error);
            });

    };

    /**
     * To update username and display a message while not a valid email.
     *
     * @param input
     */
    handleUsername = input => {
        this.setState({username: input.target.value});

        let errors = validate('username', this.state.username);
        this.updateErrors('username', errors ? 'Please enter a valid email.' : '');
    };

    /**
     * To update the password and clear any errors from a login failure attempt
     *
     * This value is not validated on purpose.
     *
     * @param input
     */
    handlePassword = input => {
        this.setState({
            password: input.target.value,
            errors: {...this.state.errors, password: ''}
        });
    };

    /**
     * To enable login once credential input is acceptable
     *
     * @returns {boolean}
     */
    loginDisabled = () => this.state.username === ''
        || this.state.errors.username !== ''
        || this.state.password === '';

    render() {
        const {classes, gridWidth} = this.props;

        return (
            <form method="POST" onSubmit={this.loginUser}>
                <Grid
                    justify="center"
                    container
                    direction="column"
                    alignItems="center"
                    className={classes.container}
                >
                    <Typography variant="h5">User Login</Typography>

                    <Grid item xs={gridWidth}>
                        <TextField
                            label="Email"
                            placeholder="Please enter your username address"
                            onChange={this.handleUsername}
                            className={classes.textField}
                            margin="normal"
                            error={this.state.errors.username !== ''}
                            helperText={this.state.errors.username}
                        />
                    </Grid>

                    <Grid item xs={gridWidth}>
                        <TextField
                            type="password"
                            label="Password"
                            placeholder="Please enter your password"
                            onChange={this.handlePassword}
                            className={classes.textField}
                            margin="normal"
                            error={this.state.errors.password !== ''}
                            helperText={this.state.errors.password}
                        />
                    </Grid>

                    <Grid item xs={gridWidth} className={classes.buttons}>
                        <Button variant="contained" color="primary" type="submit"
                                disabled={this.loginDisabled()}>
                            Login
                        </Button>

                        <Button variant="contained" component={Link} to="/user/create" color="default">
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
