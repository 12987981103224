export const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2%',
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 256,
    },

    textArea: {
        width: 512
    },

    buttons: {
        '& button': {
            margin: '1em',
            minWidth: 92,
        },
    },

    thankYou: {
        padding: theme.spacing(2),
        marginTop: 100,
        marginBottom: 100,
    },
});
