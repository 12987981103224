import React, {Component, Fragment} from 'react';
import {KeyboardShortcuts, MidiNumbers} from 'react-piano';
import 'react-piano/dist/styles.css';

import {Query} from 'react-apollo';
import SequenceSelect from '../sequence/component/select';
import {GET_SEQUENCES} from '../sequence/gql/query';
import PianoWithPlayback from './piano';
import _ from 'lodash';

import Metronome, {DEFAULT_TEMPO} from '../metronome';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import {withStyles} from '@material-ui/core';
import styles, {GRID_SPACING} from '../style/grid';
import {BtnPlay, BtnDecrease, BtnVolume, BtnIncrease} from './ui/button';
import {
    FREQUENCY_OFFSET_INCREMENT, MIN_FREQUENCY_OFFSET, MAX_FREQUENCY_OFFSET,
    GAIN_INCREMENT, MIN_GAIN, MAX_GAIN,
} from './synth';

import {MIN_OCTAVE_OFFSET, MAX_OCTAVE_OFFSET} from './tone';
import {PianoControl} from '../style/piano-control';

class Playback extends Component {
    constructor(props) {
        super(props);

        this.sequences = [];

        this.note = {
            first: 'C4',
            last: 'B4',
        };

        this.setNoteRange();

        this.nextTone = 0;

        this.state = {
            tempo: DEFAULT_TEMPO,
            selected: '0',
            recording: [],
            isPlaying: false,
            isTicking: false,
            syncTicking: false,
            keyboardShortcuts: [],
            noteRange: this.noteRange,
            gain: .5,
            frequencyOffset: 0,
            octaveOffset: 0,
        };

        this.onChangeSequence = this.onChangeSequence.bind(this);
    }

    setNoteRange = () => {
        this.noteRange = {
            first: MidiNumbers.fromNote(this.note.first),
            last: MidiNumbers.fromNote(this.note.last),
        };
    };

    enableKeyboardShortcuts = () => {
        this.setState({
            keyboardShortcuts: KeyboardShortcuts.create({
                firstNote: this.noteRange.first,
                lastNote: this.noteRange.last,
                keyboardConfig: KeyboardShortcuts.HOME_ROW,
            }),
        });
    };

    /**
     * To enable keyboard shortcuts after piano has rendered
     */
    componentDidMount = () => {
        this.enableKeyboardShortcuts();
        document.addEventListener('keydown', this.handleShortcuts);
    };

    /**
     * To remove the listener for the metronome and volume keys
     */
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleShortcuts);
    }

    /**
     * left = 37
     * up = 38
     * right = 39
     * down = 40
     *
     * @param event
     */
    handleShortcuts = event => {
        if (event.keyCode === 37) {
            this.handleLeftArrow(event);

        } else if (event.keyCode === 39) {
            this.handleRightArrow(event);

        } else if (event.keyCode === 38) {
            this.handleUpArrow(event);

        } else if (event.keyCode === 40) {
            this.handleDownArrow(event);
        }
    };

    /**
     * To handle the left arrow key event
     *
     * Decrease tempo when left arrow is pressed and if
     * the shift key is also pressed decrease the gain
     *
     * @param event
     */
    handleLeftArrow = event => {
        event.preventDefault();

        if (event.shiftKey) {
            this.gainDown();
        } else {
            this.decreaseTempo();
        }
    };

    /**
     * To handle the rignt arrow key event
     *
     * Increase tempo when left arrow is pressed and if
     * the shift key is also pressed increase the gain
     *
     * @param event
     */
    handleRightArrow = event => {
        event.preventDefault();

        if (event.shiftKey) {
            this.gainUp();
        } else {
            this.increaseTempo();
        }
    };

    handleUpArrow = event => {
        event.preventDefault();

        if (event.ctrlKey) {
            this.frequencyOffsetUp();
        } else if (event.shiftKey) {
            this.octaveOffsetUp();
        }
    };

    handleDownArrow = event => {
        event.preventDefault();

        if (event.ctrlKey) {
            this.frequencyOffsetDown();
        } else if (event.shiftKey) {
            this.octaveOffsetDown();
        }
    };

    /**
     * To set the state of the piano to a valid sequence
     *
     * @param e
     */
    onChangeSequence = e => {
        const selected = e.target.value;

        this.setState({selected});

        if (selected === '0') {
            this.setState({isPlaying: false});
        }
    };

    /**
     * To hold the sequences after fetch from server
     *
     * The sequences are retrieved as an array. These must be converted
     * to a list of objects indexed by id.
     *
     * @param sequences
     */
    setSequences = sequences => {
        this.sequences = _.keyBy(sequences, sequence => sequence.id);
    };

    /**
     * To get a sequence if already set
     *
     * @param selected
     * @returns {*}
     */
    getSequence = selected => this.sequences[selected] || [];

    /**
     * To convert the tones of the sequence to an react piano structure
     *
     * @returns {Array}
     */
    getTones = sequence => {
        let tones = [];

        if (sequence.id) {
            tones = {
                mode: 'PLAYING',
                currentEvents: _.map(sequence.tones.split(' '), tone => {
                    return ({
                        midiNumber: MidiNumbers.fromNote(tone + 3),
                        ticks: 0,
                        duration: 1,
                    });
                }),
            };
        }

        return tones;
    };

    /**
     * To set the next tone to play in the sequence
     *
     * Only increment to the next tone when the tick is set to zero.
     *
     * @returns {number}
     */
    getNextTone = tones => {
        const nextTone = this.nextTone++;

        if (this.nextTone >= tones.length) {
            this.nextTone = 0;
        }

        return nextTone;
    };

    /**
     * To play the next note in the recording.
     *
     * This function is used by the metronome for every tick.
     *
     * If playing mode then the next even is set to the recoding if any
     *
     * If we are in recording mode then the previously recorded tone
     * needs to be incremented.
     */
    playNextTone = () => {
        const sequence = this.getSequence(this.state.selected);

        if (sequence.tones.length) {
            const event = this.getTones(sequence);
            const nextTone = this.getNextTone(event.currentEvents);

            this.setRecording({
                mode: 'PLAYING',
                currentEvents: [event.currentEvents[nextTone]],
            });
        }

        this.tempoDisplayToggle = !this.tempoDisplayToggle;
    };

    /**
     * To append a note to the recording
     *
     * @param value
     */
    setRecording = value => {
        this.setState({
            recording: Object.assign({}, this.state.recording, value),
        });
    };

    /**
     * To start the piano if a sequence has been selected
     *
     * @returns {boolean}
     */
    setPlaying = () => {
        let isPlaying = false;
        let recording = [];

        if (this.state.selected === '0') {
            isPlaying = false;
        } else {
            isPlaying = !this.state.isPlaying;
        }

        this.setState({
            isPlaying, recording,
        });
    };

    /**
     * To start the the metronome ticks and sync the ticking to the tone
     *
     * The tone should be be togged simultaneously with the metronome when clicked.
     */
    handleTicking = () => {
        this.setState({syncTicking: !this.state.syncTicking});

        this.setTicking(!this.state.isTicking);
    };

    /**
     * To toggle the metronome ticking sound
     */
    setTicking = isTicking => {
        this.setState({isTicking});
    };

    /**
     * To decrease the tempo of the metronome
     */
    decreaseTempo = () => {
        this.setState({
            tempo: this.state.tempo - 1,
        });
    };

    /**
     * To increase the tempo of the metronome
     */
    increaseTempo = () => {
        this.setState({
            tempo: this.state.tempo + 1,
        });
    };

    /**
     * To increase the gain of the pitch
     *
     * @returns {number}
     */
    gainUp = () => {
        let gain = this.state.gain;

        if (gain < MAX_GAIN) {
            gain += GAIN_INCREMENT;
        }

        this.setState({gain: gain >= 1 ? 1 : gain});
    };

    /**
     * to decrease the gain of the pitch
     *
     * @returns {number}
     */
    gainDown = () => {
        let gain = this.state.gain;

        if (gain > MIN_GAIN) {
            gain -= GAIN_INCREMENT;
        }

        this.setState({gain: gain >= 0 ? gain : 0});
    };

    /**
     * To increase the gain of the pitch
     *
     * @returns {number}
     */
    frequencyOffsetUp = () => {
        if (this.state.frequencyOffset < MAX_FREQUENCY_OFFSET) {
            this.setState({
                frequencyOffset: this.state.frequencyOffset + FREQUENCY_OFFSET_INCREMENT
            });
        }
    };

    /**
     * To increase the octave offset
     */
    octaveOffsetUp = () => {
        if (this.state.octaveOffset < MAX_OCTAVE_OFFSET) {
            this.setState({octaveOffset: this.state.octaveOffset + 1})
        }
    };

    /**
     * to decrease the gain of the pitch
     *
     * @returns {number}
     */
    frequencyOffsetDown = () => {
        if (this.state.frequencyOffset > MIN_FREQUENCY_OFFSET) {
            this.setState({
                frequencyOffset: this.state.frequencyOffset - FREQUENCY_OFFSET_INCREMENT
            });
        }
    };

    /**
     * To decrease the octave offset
     */
    octaveOffsetDown = () => {
        if (this.state.octaveOffset > MIN_OCTAVE_OFFSET) {
            this.setState({octaveOffset: this.state.octaveOffset - 1})
        }
    };

    controlsTop = () => {
        const {classes} = this.props;

        return (
            <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Volume <span
                            className="hint">(shift + left/right arrows)</span></label>
                        <Paper className={classes.paper}>
                            {this._volumeController()}
                        </Paper>
                    </PianoControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Frequency <span
                            className="hint">(ctrl + down/up arrows)</span></label>
                        <Paper className={classes.paper}>
                            {this._frequencyOffsetController()}
                        </Paper>
                    </PianoControl>
                </Grid>
            </Grid>
        );
    };

    _volumeController = () => {
        return (
            <Grid container>
                <Grid item xs={4}>
                    <BtnDecrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true} s
                        size="small"
                        onClick={() => this.gainDown()}
                    />
                </Grid>

                <Grid item xs={4}>
                    <span>{Math.round(this.state.gain * 100) + '%'}</span>
                </Grid>

                <Grid item xs={4}>
                    <BtnIncrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.gainUp()}
                    />
                </Grid>
            </Grid>
        );
    };

    _frequencyOffsetController = () => {
        return (
            <Grid container>
                <Grid item xs={4}>
                    <BtnDecrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.frequencyOffsetDown()}
                    />
                </Grid>

                <Grid item xs={4}>
                    <span>{(this.state.frequencyOffset + 440) + 'Hz'}</span>
                </Grid>

                <Grid item xs={4}>
                    <BtnIncrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.frequencyOffsetUp()}
                    />
                </Grid>
            </Grid>
        );
    };

    /**
     * To render the piano keyboard in a single row
     *
     * @returns {*}
     */
    piano = () => {
        const {classes} = this.props;

        return (
            <PianoControl className="piano">
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        {this._piano()}
                    </Grid>
                </Paper>
            </PianoControl>
        );
    };

    /**
     * To render the piano for playback
     *
     * The armed is set to true during playback to allow control
     * of the notes when not playing
     * @returns {*}
     * @private
     */
    _piano = () => (
        <Metronome
            active={this.state.isPlaying}
            tempo={this.state.tempo}
            onTick={this.playNextTone}
        >
            <PianoWithPlayback
                gain={this.state.gain}
                frequencyOffset={this.state.frequencyOffset}
                octaveOffset={this.state.octaveOffset}
                octaveOffsetDown={this.octaveOffsetDown}
                octaveOffsetUp={this.octaveOffsetUp}
                noteRange={this.state.noteRange}
                noteDuration={60 / this.state.tempo}
                syncTicking={this.state.syncTicking}
                setTicking={this.setTicking}
                isTicking={this.state.isTicking}
                armed={!this.state.isPlaying}
                recording={this.state.recording}
                keyboardShortcuts={this.state.keyboardShortcuts}
            />
        </Metronome>
    );

    /**
     * To display the metronome controls
     *
     * @returns {*}
     */
    controlsBottom = () => {
        const {classes} = this.props;

        return (
            <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Metronome <span className="hint">(left/right arrows)</span></label>
                        <Paper className={classes.paper}>
                            {this.metronome()}
                        </Paper>
                    </PianoControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <PianoControl>
                        <label>Octave <span className="hint">(shift + up/down arrows)</span></label>
                        <Paper className={classes.paper}>
                            {this.octaveOffset()}
                        </Paper>
                    </PianoControl>
                </Grid>
            </Grid>
        );
    };

    metronome = () => {
        return (
            <Grid container>
                <Grid item xs={3}>
                    <BtnDecrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true} s
                        size="small"
                        onClick={() => this.decreaseTempo()}
                    />
                </Grid>

                <Grid item xs={2}>
                    <span>{this.state.tempo}</span>
                </Grid>

                <Grid item xs={3}>
                    <BtnIncrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.increaseTempo()}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Metronome
                        active={this.state.isTicking}
                        tempo={this.state.tempo}
                    >
                        <BtnVolume
                            color="primary"
                            size="small"
                            on={this.state.isTicking}
                            onClick={this.handleTicking}/>
                    </Metronome>
                </Grid>
            </Grid>
        );
    };

    /**
     * To display controllers to jump pitch by octave
     *
     * @returns {*}
     */
    octaveOffset = () => {
        return (
            <Grid container>
                <Grid item xs={4}>
                    <BtnDecrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.octaveOffsetDown()}
                    />
                </Grid>

                <Grid item xs={4}>
                    {this.octaveOffsetLabel(this.state.octaveOffset)}
                </Grid>

                <Grid item xs={4}>
                    <BtnIncrease
                        variant="text"
                        color="primary"
                        disableRipple={true}
                        disableFocusRipple={true}
                        size="small"
                        onClick={() => this.octaveOffsetUp()}
                    />
                </Grid>
            </Grid>
        );
    };

    /**
     * To set the label of the octave offset
     *
     * @param octaveOffset
     * @returns {*}
     */
    octaveOffsetLabel = octaveOffset => {
        let octaveLabel = octaveOffset;

        if (octaveLabel === 0) {
            octaveLabel = 'Home';
        } else {
            if (octaveLabel > 0) {
                octaveLabel = '+' + octaveOffset;
            }
        }

        return (
            <span>
        {octaveLabel}{octaveOffset === 0 || <sup>8va---</sup>}
      </span>
        );
    };

    _sequences = () => {
        const {classes} = this.props;

        return (
            <Grid container>
                <Grid item xs={8}>

                    <Query query={GET_SEQUENCES}>
                        {({loading, error, data}) => {
                            if (loading) return 'Loading...';
                            if (error) return `Error! ${error.message}`;

                            this.setSequences(data.sequences);

                            return (
                                <SequenceSelect
                                    classes={classes}
                                    disabled={false}
                                    sequences={data.sequences}
                                    selected={this.state.selected}
                                    onChange={this.onChangeSequence}
                                />
                            );
                        }}
                    </Query>

                </Grid>

                <Grid item xs={4}>
                    <BtnPlay
                        isPlaying={this.state.isPlaying}
                        onClick={this.setPlaying}/>
                </Grid>
            </Grid>
        );
    };

    render() {
        return (
            <Fragment>
                {this.controlsTop()}

                {this.piano()}

                {this.controlsBottom()}
            </Fragment>
        );
    }
}

export default withStyles(styles)(Playback);
