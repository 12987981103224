import React from 'react';
import {MidiNumbers} from 'react-piano';

/**
 * getAttributes structure:
 *
 * isAccidental
 * midiNumber
 * octave
 * pitchName
 *
 * @param key
 * @param midiNumber
 * @param ticks
 */
export default ({midiNumber, ticks}) => {
    const tone = MidiNumbers.getAttributes(midiNumber);
    const label = tone.pitchName;

    return (
        <div className="container">
            <span className="tone">{label}</span>
            |
            <span className={"duration"}>{ticks}</span>
        </div>
    );
}
