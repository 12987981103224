import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Grid, Button, List, ListItem, ListItemText} from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../styles';

import {Mutation} from 'react-apollo';
import {USER_CREATE_GQL, USER_UPDATE_GQL} from '../../gql/mutation';

import {setSchemaInputValues} from './index';

/**
 * To set the variables prop with the schema input values
 *
 * @param form
 * @returns object
 */
const setVariables = form => {
    return {
        input: setSchemaInputValues(form)
    }
}

export class StepFourConfirm extends Component {
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    values = (values, classes, gridWidth) => {
        return (<Grid item xs={gridWidth}>
                <List>
                    <ListItem>
                        <ListItemText primary={values.firstName}
                                      secondary="First Name"/>
                        <ListItemText primary={values.lastName} secondary="Last Name"/>

                    </ListItem>

                    <ListItem>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={values.username} secondary="Email"/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={values.city} secondary="City"/>
                        <ListItemText primary={values.state} secondary="State"/>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={values.country} secondary="Country"/>

                    </ListItem>

                    <ListItem>
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={values.instrument}
                            secondary="Instrument"
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary={values.bio} secondary="Biography"/>
                    </ListItem>
                </List>
            </Grid>
        );
    };

    render() {
        const {values, classes, gridWidth} = this.props;
        const USER_SAVE_GQL = values.id ? USER_UPDATE_GQL : USER_CREATE_GQL;

        return (
            <Grid container
                  justify="center"
                  direction="column"
                  alignItems="center"
                  className={classes.container}
            >
                {this.values(values, classes, gridWidth)}

                <Mutation mutation={USER_SAVE_GQL} variables={setVariables(values)}>
                    {saveUser => (
                        <Grid item xs={gridWidth} className={classes.buttons}>
                            <Button variant="contained" color="default"
                                    onClick={this.back}>
                                <NavigateBefore/> Back
                            </Button>

                            <Button variant="contained" color="primary" onClick={() =>
                                saveUser().then(() => {
                                    this.props.nextStep();
                                })}>
                                Confirm <NavigateNext/>
                            </Button>
                        </Grid>
                    )}
                </Mutation>
            </Grid>
        );
    }
}

StepFourConfirm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepFourConfirm);
