import gql from 'graphql-tag';

export const USER_CREATE_INPUT = gql`
    fragment userCreateFields on User {
        username
        firstName
        lastName
        city
        state
        country
        instrument
        bio
    }
`;

export const USER_CREATE_GQL = gql`
    ${USER_CREATE_INPUT}

    mutation userCreate($input: UserCreateInput!) {
        userCreate(input: $input) {
            ...userCreateFields
        }
    }
`;

export const USER_UPDATE_INPUT = gql`
    fragment userUpdateFields on User {
        id
        username
        firstName
        lastName
        city
        state
        country
        instrument
        bio
    }
`;

export const USER_UPDATE_GQL = gql`
    ${USER_UPDATE_INPUT}

    mutation userUpdate($id: ID!, $input: UserUpdateInput!) {
        userUpdate(id: $id, input: $input) {
            ...userUpdateFields
        }
    }
`;